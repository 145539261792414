.side-nav-bar {
  width: 85px;

  .juan {
    display: block;
    margin: 8px auto;
    width: 50px;
    height: auto;
    padding: 8px 0;
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    top: 30%;
    height: 60%;
    margin-top: -60%;
    width: 100%;
  }

  .social-media {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
      }
    }
  }
}
